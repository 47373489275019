var exports = {};
// We define these manually to ensure they're always copied
// even if they would move up the prototype chain
// https://nodejs.org/api/http.html#http_class_http_incomingmessage
const knownProps = ["destroy", "setTimeout", "socket", "headers", "trailers", "rawHeaders", "statusCode", "httpVersion", "httpVersionMinor", "httpVersionMajor", "rawTrailers", "statusMessage"];

exports = (fromStream, toStream) => {
  const fromProps = new Set(Object.keys(fromStream).concat(knownProps));

  for (const prop of fromProps) {
    // Don't overwrite existing properties
    if (prop in toStream) {
      continue;
    }

    toStream[prop] = typeof fromStream[prop] === "function" ? fromStream[prop].bind(fromStream) : fromStream[prop];
  }
};

export default exports;